import React, { useEffect } from 'react';
import { Button, Form, Input, Select } from 'antd';
import AddressInput from '../Inputs/AddressInput';
import { HUB_TAG } from '../../constants';
import './HubForm.scss';

const initForm = {
  name: '',
  tag: 'CITY_HUB',
  startAddress: undefined,
};
const HubForm = ({ handleSubmit, action, hub, handleCancel }) => {
  const [form] = Form.useForm();

  const setParams = (values) => {
    const params = { ...values };
    params.startAddress = values.startAddress?.address;
    params.startAddressLat = values.startAddress?.latLng?.lat;
    params.startAddressLng = values.startAddress?.latLng?.lng;

    params.endAddress = values.endAddress?.address || null;
    params.endAddressLat = values.endAddress?.latLng?.lat || null;
    params.endAddressLng = values.endAddress?.latLng?.lng || null;
    return params;
  };
  const onFinishForm = (values) => {
    handleSubmit && handleSubmit(setParams(values));
  };

  useEffect(() => {
    if (action === 'new') {
      form.setFieldsValue(initForm);
    } else if (hub) {
      form.setFieldsValue({
        ...hub,
        startAddress: {
          address: hub?.startAddress,
          latLng: {
            lat: hub?.startAddressLat,
            lng: hub?.startAddressLng,
          },
        },
        endAddress:
          hub.endAddress && hub.endAddressLat
            ? {
                address: hub.endAddress,
                latLng: {
                  lat: hub.endAddressLat,
                  lng: hub.endAddressLng,
                },
              }
            : undefined,
      });
    }
  }, [action, hub]);

  return (
    <div className="HubForm">
      <div className="FormHeader"></div>
      <div className="FormContent">
        <Form
          form={form}
          scrollToFirstError
          layout="vertical"
          requiredMark={false}
          colon={false}
          onFinish={onFinishForm}
        >
          <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Required' }]}>
            <Input placeholder="Name" />
          </Form.Item>
          <Form.Item label="Tag" name="tag" rules={[{ required: true, message: 'Required' }]}>
            <Select
              placeholder="Select Tag"
              options={Object.keys(HUB_TAG).map((key) => ({
                value: key,
                label: HUB_TAG[key],
              }))}
            />
          </Form.Item>
          <Form.Item
            label="Start Address"
            name="startAddress"
            rules={[
              {
                validator: (_, value) => {
                  if (value?.address && value?.latLng?.lat && value?.latLng?.lng) {
                    return Promise.resolve();
                  }

                  if (isEmpty(value?.address)) {
                    return Promise.reject(new Error('Required'));
                  }

                  return Promise.reject(new Error('Invalid'));
                },
              },
            ]}
          >
            <AddressInput
              placeholder="Start Address"
              onSelect={(value) => form.setFieldsValue({ startAddress: value })}
            />
          </Form.Item>
          <Form.Item
            label="End Address"
            name="endAddress"
            rules={[
              {
                validator: (_, value) => {
                  if ((value?.address && value?.latLng?.lat && value?.latLng?.lng) || !value) {
                    return Promise.resolve();
                  }

                  return Promise.reject(new Error('Invalid'));
                },
              },
            ]}
          >
            <AddressInput placeholder="End Address" onSelect={(value) => form.setFieldsValue({ endAddress: value })} />
          </Form.Item>
        </Form>
      </div>
      <div className="FormFooter">
        <Button key="cancel" onClick={handleCancel}>
          Cancel
        </Button>
        <Button type="primary" block size="large" onClick={() => form.submit()}>
          {action === 'new' ? 'Create' : 'Update'}
        </Button>
      </div>
    </div>
  );
};

export default HubForm;
