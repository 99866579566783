import React, { memo, useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import * as Papa from 'papaparse';
import { Spin, Upload } from 'antd';
import _isEmpty from 'lodash/isEmpty';
import { UploadOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import './UploadOrders.scss';
import { Link, useNavigate } from 'react-router-dom';
import { doUploadOrders } from '../modules/orders/stores/thunk';
import { ReactSVG } from 'react-svg';
import moment from 'moment';
import { setError } from '../redux/slice';
import { DATE_BACKEND_FORMAT, TIME_FORMAT } from '../constants';
import { ExcelDateToJSDate } from '../utils/common';
import { isNumber } from 'lodash';

const MAP_CSV_DATA = {
  'Order Ref': 'orderRef',
  'Delivery Date': 'deliveryDate',
  Load: 'load',
  Name: 'name',
  Description: 'description',
  'Length(cm)': 'sizeLength',
  'Width(cm)': 'sizeWidth',
  'Height(cm)': 'sizeHeight',
  'Weight(kg)': 'weight',
  'Pickup Name': 'pickupName',
  'Pickup Address': 'pickupAddress',
  'Pickup Address Latitude': 'pickupAddressLat',
  'Pickup Address Longitude': 'pickupAddressLng',
  'Pickup Time Start': 'pickupTimeStart',
  'Pickup Time End': 'pickupTimeEnd',
  'Pickup Phone': 'pickupPhone',
  'Pickup Note': 'pickupNote',
  'Pickup Duration': 'pickupDuration',
  'Delivery Name': 'deliveryName',
  'Delivery Email': 'deliveryEmail',
  'Delivery Address': 'deliveryAddress',
  'Delivery Address Latitude': 'deliveryAddressLat',
  'Delivery Address Longitude': 'deliveryAddressLng',
  'Delivery Phone': 'deliveryPhone',
  'Delivery Time Start': 'deliveryTimeStart',
  'Delivery Time End': 'deliveryTimeEnd',
  'Delivery Note': 'deliveryNote',
  'Delivery Duration': 'deliveryDuration',
  Partner: 'partner',
  Types: 'types',
};

const fieldRequired = ['name', 'pickupName', 'deliveryName', 'pickupAddress', 'deliveryAddress', 'deliveryDate'];

const UploadOrders = () => {
  const [fileList, setFileList] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isFetching = useSelector((state) => state.order.isFetching);
  const action = useSelector((state) => state.order.action);
  const status = useSelector((state) => state.order.status);

  useEffect(() => {
    if (['upload']?.includes(action) && status === 'succeeded') {
      navigate('/admin/orders');
    }
  }, [isFetching]);

  const handleBeforeUpload = (file) => {
    const fileReader = new FileReader();
    fileReader.onload = (event) => {
      try {
        const { result } = event.target;
        let data = [];
        const isExcel = file.name.endsWith('.xlsx') || file.name.endsWith('.xls');
        if (file.name.endsWith('.xlsx') || file.name.endsWith('.xls')) {
          const workbook = XLSX.read(result, { type: 'binary', cellText: false, cellDates: false, cellNF: false });
          for (const sheet in workbook.Sheets) {
            data = data.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
          }
        } else if (file.name.endsWith('.csv')) {
          Papa.parse(result, {
            header: true,
            skipEmptyLines: true,
            dynamicTyping: true,
            complete: (results) => {
              data = results.data;
            },
          });
        } else {
          console.log('File type is not supported.');
          return;
        }

        handleUploadOrders(data, isExcel);
      } catch (e) {
        console.log('e', e);
      }
    };

    fileReader.readAsBinaryString(file);
  };

  const handleOnRemove = () => {
    setFileList([]);
  };

  const checkValidData = (items) => {
    const errors = [];
    const missingFields = [];

    items.forEach((item) => {
      fieldRequired.forEach((field) => {
        if (!item[field] && !missingFields?.includes(field)) {
          missingFields.push(field);
        }
      });
    });

    if (missingFields.length > 0) {
      const displayNameFields = missingFields.map((field) => {
        const key = Object.keys(MAP_CSV_DATA).find((key) => MAP_CSV_DATA[key] === field);
        return key || field;
      });
      errors.push(`Missing required field: ${displayNameFields.join(', ')}`);
    }

    const invalidDeliveryDate = items.some(
      (item) => item['deliveryDate'] && !moment(item['deliveryDate'], 'DD-MM-YYYY').isValid(),
    );

    if (invalidDeliveryDate) {
      errors.push('Invalid format Delivery Date');
    }
    return errors;
  };

  const handleUploadOrders = async (fileRecords, isExcel) => {
    const items = fileRecords.map((record) => {
      const obj = {};
      Object.keys(MAP_CSV_DATA)?.forEach((key) => {
        const fieldName = MAP_CSV_DATA[key];
        const value = record[key];
        if (
          [
            'Length(cm)',
            'Width(cm)',
            'Height(cm)',
            'Weight(kg)',
            'Load',
            'Delivery Address Latitude',
            'Delivery Address Longitude',
            'Pickup Address Latitude',
            'Pickup Address Longitude',
            'Pickup Duration',
            'Delivery Duration',
          ]?.includes(key)
        ) {
          obj[fieldName] = value ? Number(value) : value;
        } else if (key === 'Delivery Date' && value) {
          obj[fieldName] =
            isNumber(value) && isExcel
              ? moment(ExcelDateToJSDate(value)).format(DATE_BACKEND_FORMAT)
              : moment(value, 'DD/MM/YYYY').format(DATE_BACKEND_FORMAT);
        } else if (
          ['Delivery Time Start', 'Delivery Time End', 'Pickup Time Start', 'Pickup Time End']?.includes(key) &&
          value
        ) {
          obj[fieldName] = isNumber(value) && isExcel ? moment(ExcelDateToJSDate(value)).format(TIME_FORMAT) : value;
        } else if (key === 'Types') {
          obj[fieldName] = value ? value.split(',') : [];
        } else {
          obj[fieldName] = value ? String(value) : value;
        }
      }, []);
      return obj;
    });

    const errors = checkValidData(items);
    if (errors.length > 0) {
      dispatch(setError({ message: errors.join('\n') }));
      return;
    }

    try {
      const res = await dispatch(doUploadOrders({ items }));
      if (_isEmpty(res?.error)) {
        handleOnRemove();
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Spin spinning={isFetching}>
      <div className="UploadOrders Content">
        <div className="ContentHeader">
          <div className="HeaderTitle">
            <Link to={`/orders`}>Orders</Link>
            <ReactSVG className="icon" src="/icons/arrow-forward.svg" />
            <span className="Link">Upload Orders</span>
          </div>
        </div>
        <div className="ContentTitle">
          <h2>Upload Orders</h2>
        </div>
        <div className="ContentMain">
          <Upload.Dragger
            multiple={false}
            maxCount={1}
            accept=".xlsx, .xls, .csv"
            showUploadList={false}
            beforeUpload={handleBeforeUpload}
            listType="picture-card"
            fileList={fileList}
            onRemove={handleOnRemove}
            onChange={(info) => setFileList(info?.fileList)}
            customRequest={async ({ onSuccess, onError }) => {
              try {
                onSuccess('ok');
              } catch (e) {
                console.log(e);
                onError();
              }
            }}
          >
            <p className="upload-text">Drag and drop to upload or</p>
            <p className="upload-drag-icon">
              Browse
              <UploadOutlined />
            </p>
            <p className="upload-supported">Supports files in .xlsx, .xls and .csv format</p>
          </Upload.Dragger>
          <div className="sample">
            <p>
              Download Sample File:{' '}
              <a target="_blank" href="/import-orders/sample.csv" rel="noopener noreferrer">
                sample.csv
              </a>
            </p>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default memo(UploadOrders);
