import React, { useEffect, useState } from 'react';
import './Scheduler.scss';
import { Button, Modal, Spin } from 'antd';
import { ReactSVG } from 'react-svg';
import moment from 'moment';
import { DATE_BACKEND_FORMAT, FLEET_TAG, TIME_FORMAT } from '../constants';
import uniq from 'lodash/uniq';
import groupBy from 'lodash/groupBy';
import sumBy from 'lodash/sumBy';
import { useDispatch, useSelector } from 'react-redux';
import { doGetDrivers } from '../modules/drivers/stores/thunk';
import { selectAllDrivers } from '../modules/drivers/stores/slice';
import { selectAllScheduledForms } from '../modules/scheduler-forms/stores/slice';
import { stringToColorHex } from '../utils/common';
import { hexToHexA } from '../utils/color';
import {
  doCreateScheduledForm,
  doDeleteScheduledForm,
  doGetScheduledForms,
  doImportFromRangeDateSchedulerForm,
  doUpdateScheduledForm,
} from '../modules/scheduler-forms/stores/thunk';
import { selectAllFleets } from '../modules/fleets/stores/slice';
import { doGetFleets } from '../modules/fleets/stores/thunk';
import ShiftForm from '../components/Forms/ShiftForm';

const initStateModal = {
  open: false,
  shift: null,
  action: 'ide',
};

export default function SchedulerPage() {
  const [currentDates, setCurrentDates] = useState([]);
  const [stateModal, setStateModal] = useState(initStateModal);
  const [shiftData, setShiftData] = useState({});
  const dispatch = useDispatch();

  const drivers = useSelector((state) => selectAllDrivers(state));
  const fleets = useSelector((state) => selectAllFleets(state));
  const schedulerForms = useSelector((state) => selectAllScheduledForms(state));
  const isFetchingDriver = useSelector((state) => state.driver.isFetching);
  const isFetchingFleet = useSelector((state) => state.fleet.isFetching);

  const { isFetching: isFetchingSchedulerForm, action, status } = useSelector((state) => state.schedulerForm);

  const isFetching = isFetchingDriver || isFetchingFleet || isFetchingSchedulerForm;

  useEffect(() => {
    if (schedulerForms.length > 0) {
      const groupByDate = groupBy(schedulerForms, 'date');
      const groupByDriverId = groupBy(schedulerForms, 'driverId');
      setShiftData({
        groupByDate,
        groupByDriverId,
      });
    } else {
      setShiftData({});
    }
  }, [schedulerForms]);

  const getSevenDays = (startDate, type = 'add') => {
    const days = [];
    const day = moment(startDate, DATE_BACKEND_FORMAT);
    for (let i = 0; i < 7; i++) {
      days.push(day.format(DATE_BACKEND_FORMAT));
      type === 'subtract' ? day.subtract(1, 'days') : day.add(1, 'days');
    }

    return type === 'subtract' ? days.reverse() : days;
  };

  const today = moment().format(DATE_BACKEND_FORMAT);

  const handleCancelModal = () => {
    setStateModal(initStateModal);
  };

  const handleSubmitModal = (data) => {
    if (stateModal.action === 'new') {
      dispatch(doCreateScheduledForm(data));
    } else {
      data.id = stateModal.shift.id;
      dispatch(doUpdateScheduledForm(data));
    }
  };

  const handleDeleteModal = () => {
    if (stateModal.shift?.id) dispatch(doDeleteScheduledForm(stateModal.shift.id));
  };

  const onClickNew = (shift = {}) => {
    setStateModal({
      ...stateModal,
      open: true,
      shift,
      action: 'new',
    });
  };

  const onClickEdit = (record) => {
    setStateModal({
      ...stateModal,
      open: true,
      shift: record,
      action: 'edit',
    });
  };

  const calculateDuration = (start, end) => {
    const duration = moment.duration(moment(end, TIME_FORMAT).diff(moment(start, TIME_FORMAT))).asHours();
    return Math.round((duration + Number.EPSILON) * 100) / 100;
  };

  useEffect(() => {
    if (['create', 'update', 'delete', 'importFromRangeDate']?.includes(action) && status === 'succeeded') {
      dispatch(doGetDrivers({ take: 1000000 }));
      dispatch(doGetScheduledForms({ from: currentDates[0], to: currentDates[6] }));
      handleCancelModal();
    }
  }, [isFetchingSchedulerForm]);

  useEffect(() => {
    const days = getSevenDays(moment().startOf('isoWeek').format(DATE_BACKEND_FORMAT));
    setCurrentDates(days);
    dispatch(doGetDrivers({ take: 1000000 }));
    dispatch(doGetFleets({ take: 1000000 }));
  }, []);

  useEffect(() => {
    if (currentDates.length > 0) dispatch(doGetScheduledForms({ from: currentDates[0], to: currentDates[6] }));
  }, [currentDates]);

  const nextWeek = () => {
    const day = moment(currentDates[6], DATE_BACKEND_FORMAT);
    day.add(1, 'days');

    const days = getSevenDays(day.format(DATE_BACKEND_FORMAT), 'add');
    setCurrentDates(days);
  };

  const todayWeek = () => {
    const day = moment();

    const days = getSevenDays(day.format(DATE_BACKEND_FORMAT), 'add');
    setCurrentDates(days);
  };

  const prevWeek = () => {
    const day = moment(currentDates[0], DATE_BACKEND_FORMAT);
    day.subtract(1, 'days');

    const days = getSevenDays(day.format(DATE_BACKEND_FORMAT), 'subtract');
    setCurrentDates(days);
  };

  const hdClickImportPreviousWeek = () => {
    const dayFrom = moment(currentDates[0], DATE_BACKEND_FORMAT).subtract(8, 'days');
    const dayTo = moment(currentDates[0], DATE_BACKEND_FORMAT).subtract(1, 'days');
    dispatch(
      doImportFromRangeDateSchedulerForm({
        fromDate: dayFrom.format(DATE_BACKEND_FORMAT),
        toDate: dayTo.format(DATE_BACKEND_FORMAT),
      }),
    );
  };

  const getTitle = () => {
    const days = currentDates.map((date) => moment(date).format('MMMM YYYY'));
    return uniq(days).join(' - ');
  };

  return (
    <Spin spinning={isFetching}>
      <div className="SchedulerPage Content">
        <div className="ContentHeader">
          <div className="HeaderTitle">Scheduler</div>
          <div className="HeaderToolBar">
            <div className="ToolBarButtons">
              <Button
                className="CreateButton ButtonPrimary"
                type="link"
                size="small"
                onClick={hdClickImportPreviousWeek}
              >
                Duplicate Last Week's Shifts
              </Button>
              <Button className="CreateButton ButtonPrimary" type="primary" size="small" onClick={onClickNew}>
                Create Shift
                <ReactSVG className="icon" src="/icons/plus.svg" />
              </Button>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="header">
            <h2>{getTitle()?.toUpperCase()}</h2>
            <div className="navigation">
              <button onClick={prevWeek} className="next">
                <ReactSVG className="icon" src="/icons/keyboard_arrow_left.svg" />
              </button>
              <button onClick={nextWeek} className="next">
                <ReactSVG className="icon" src="/icons/keyboard_arrow_right.svg" />
              </button>
              <button onClick={todayWeek}>Today</button>
            </div>
          </div>
          <div className="schedule">
            <div className="days-header">
              <div className="info"></div>
              {currentDates.map((day, index) => (
                <div key={index} className="day">
                  {moment(day, DATE_BACKEND_FORMAT).format('MMM DD (dddd)')}
                </div>
              ))}
            </div>
            <div className="scheduled-title">Scheduled Shifts</div>
            <div className=""></div>
            {/* Render each row for the scheduled shifts */}
            {drivers.map((driver, index) => {
              const color = stringToColorHex(driver?.name + `${driver.id}`);
              const backgroundOpacity = hexToHexA(color || '#93BFFF', 0.24);
              const avatarBackground = backgroundOpacity?.replace('#', '');
              const avatarColor = color?.replace('#', '');
              const dataShift = shiftData.groupByDriverId?.[driver?.id];
              const dataShiftByDate = groupBy(dataShift, 'date');
              return (
                <div key={index} className="shift-row">
                  <div className="info">
                    <div className="avatar">
                      <img
                        src={`https://ui-avatars.com/api/?length=1&name=${
                          driver?.name
                        }&size=32&font-size=0.4&bold=true&background=${
                          avatarBackground || '9863f3'
                        }&color=${avatarColor}&rounded=true`}
                        alt=""
                      />
                    </div>
                    <div className="fullName">{driver?.name}</div>
                  </div>
                  {currentDates.map((day, index) => {
                    const shifts = dataShiftByDate[day];

                    return (
                      <div key={index + day} className={`shift-detail ${day === today ? 'today-border' : ''}`}>
                        {shifts?.length ? (
                          shifts.map((data, index) => {
                            return (
                              <div
                                className="shift-content cursor-pointer"
                                key={index + day}
                                style={{ backgroundColor: backgroundOpacity }}
                                onClick={() => onClickEdit(data)}
                              >
                                <div className="top">
                                  <p style={{ color }}>
                                    <b>
                                      {data.start} - {data.end}
                                    </b>
                                    {' . '}
                                    {calculateDuration(data.start, data.end)}h
                                  </p>
                                </div>
                                <div className="bottom">
                                  <b>{FLEET_TAG[data.fleet.tag]}</b>
                                  <p>{data.fleet.name}</p>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <div
                            className="shift-content add cursor-pointer"
                            onClick={() => onClickNew({ driverId: driver.id, date: day })}
                          >
                            <ReactSVG className="icon" src="/icons/plus.svg" />
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              );
            })}
            <div className="shift-row">
              <div className="info"></div>
              {currentDates.map((day) => {
                return (
                  <div className={`shift-detail ${day === today ? 'today-border' : ''}`} key={day + 'total'}>
                    <div className="shift-content total">
                      <b>
                        {shiftData.groupByDate && Array.isArray(shiftData.groupByDate[day])
                          ? sumBy(shiftData.groupByDate[day], (shift) => {
                              return calculateDuration(shift.start, shift.end);
                            })
                          : 0}{' '}
                        hours
                      </b>
                      <p>
                        {shiftData.groupByDate && Array.isArray(shiftData.groupByDate[day])
                          ? shiftData.groupByDate[day].length
                          : 0}{' '}
                        drivers
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <Modal open={stateModal.open} onCancel={handleCancelModal} footer={[]} width={792} forceRender>
        <ShiftForm
          handleSubmit={handleSubmitModal}
          handleCancel={handleCancelModal}
          handleDelete={handleDeleteModal}
          shift={stateModal.shift}
          action={stateModal.action}
          isFetching={isFetching}
          drivers={drivers}
          fleets={fleets}
        />
      </Modal>
    </Spin>
  );
}
