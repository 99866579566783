import React, { useEffect, useState } from 'react';
import { Button, Modal, Form, Select } from 'antd';
import { STEP_TYPE } from '../../constants';

const SwapStopsToDriver = ({ state, handleOk, handleCancel, routes, solutions, orders, setMultipleStepState }) => {
  const [form] = Form.useForm();
  const [indexOptions, setIndexOptions] = useState([]);

  const orderIds = state.steps?.map((s) => s.orderId) || [];

  const options = [
    {
      label: 'Unscheduled',
      value: 'unscheduled',
      isSelectIndex: false,
    },
  ];

  routes.forEach((route) => {
    options.push({
      value: route.driver.id,
      label: route.driver.name,
      isSelectIndex: true,
    });
  });

  const handleClick = () => {
    form.submit();
  };

  useEffect(() => {
    form.setFieldsValue({ toDriverId: state.toDriverId, index: state.index });
  }, [state]);

  const changeSolutionId = (value) => {
    setMultipleStepState({
      ...state,
      toDriverId: value,
      index: null,
    });
    if (value != 'unscheduled') {
      const newIndexOptions = [];
      solutions[value]?.forEach((ss, i) => {
        if (orderIds?.includes(ss.orderId)) return;
        const order = orders.find((o) =>
          ss.type === STEP_TYPE.pickup ? o.id === ss.orderIds?.[0] : o.id === ss.orderId,
        );
        newIndexOptions.push({
          value: i,
          label: `[${ss.index}] ${ss.type === STEP_TYPE.pickup ? order.pickupAddress : order.deliveryAddress}`,
        });
      });
      setIndexOptions(newIndexOptions || []);
    } else {
      setIndexOptions([]);
    }
  };

  const changeIndexValue = (value) => {
    setMultipleStepState({
      ...state,
      index: value,
    });
  };

  return (
    <Modal
      visible={state.visibleModal}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button key="cancel" onClick={handleCancel}>
          CANCEL
        </Button>,
        <Button key="submit" type="primary" onClick={handleClick}>
          Apply
        </Button>,
      ]}
      width={370}
      className="swap-driver-modal"
      closeIcon={false}
      forceRender
    >
      <div className="SwapStopsToDriver">
        <Form form={form} scrollToFirstError hideRequiredMark colon={false} layout="vertical" onFinish={handleOk}>
          <div className="FormContent">
            <Form.Item label="Driver" name="toDriverId" rules={[{ required: true, message: 'Required' }]}>
              <Select placeholder="Drivers" onChange={changeSolutionId}>
                {options.map((item) => (
                  <Select.Option key={item.value} value={item.value}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            {state.toDriverId && indexOptions.length > 0 && (
              <Form.Item label="After" name="index" rules={[{ required: true, message: 'Required' }]}>
                <Select
                  placeholder="Index"
                  onChange={changeIndexValue}
                  filterOption={(input, option) => {
                    return (option?.key?.toLowerCase() ?? '')?.includes(input?.toLowerCase());
                  }}
                  dropdownStyle={{ minWidth: 550 }}
                  showSearch
                >
                  <Select.Option value={-1}>[0] Start</Select.Option>
                  {indexOptions.map((item) => (
                    <Select.Option key={item.label} value={item.value}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default SwapStopsToDriver;
