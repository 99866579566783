import request from '../../utils/request';

export function getRoutesByDate(params) {
  return request('/routes/get-by-date', {
    method: 'POST',
    data: params,
  });
}

export function dispatchRoutes(params) {
  return request('/routes/dispatch', {
    method: 'POST',
    data: params,
  });
}

export function optimizeRoutesByDate(params) {
  return request('/routes/optimize', {
    method: 'POST',
    data: params,
  });
}

export function getRoutes(params) {
  return request('/routes', {
    method: 'GET',
    params: params,
  });
}

export function reorderOrdersForRoutes(params) {
  const date = params.date;
  delete params.date;

  return request(`/routes/${date}/reorder-orders`, {
    method: 'POST',
    data: params,
  });
}
