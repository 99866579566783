import React, { useEffect, memo, useCallback } from 'react';
import { APIProvider, useMap, Map as GMap } from '@vis.gl/react-google-maps';
import './GoogleMap.scss';
import Marker from './Marker';
import Polyline from './Polyline';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setShouldFitbounds } from '../../redux/slice';

const MapHandler = memo(({ stops, center, active }) => {
  const map = useMap();
  const dispatch = useDispatch();
  const shouldFitbounds = useSelector((state) => state.common.shouldFitbounds);

  useEffect(() => {
    if (!map) {
      return;
    }

    if (stops?.length > 0 && shouldFitbounds) {
      const bounds = new window.google.maps.LatLngBounds();
      stops.forEach((stop) => {
        bounds.extend(new window.google.maps.LatLng(stop.latitude, stop.longitude));
      });
      map.fitBounds(bounds);
      dispatch(setShouldFitbounds(false));
    }
  }, [stops, map, shouldFitbounds]);

  useEffect(() => {
    if (!map) {
      return;
    }
    if (center?.lat && center?.lng) {
      map.panTo(new window.google.maps.LatLng(center.lat, center.lng));
    }

    if (active?.id) {
      const stop = stops.find((item) => item.id === active.id);
      if (stop?.latitude) map.panToBounds(new window.google.maps.LatLng(stop.latitude, stop.longitude), 200);
    }
  }, [center, map]);

  return null;
});

const GoogleMap = memo(({ markers, polylines, center, active }) => {
  const isActive = useCallback(
    (id) => {
      return active && id === active.id;
    },
    [active],
  );

  const renderMarkers = useCallback(
    (items) => {
      return items.map((item) => {
        return (
          <Marker
            key={item.id}
            position={{
              lat: item.latitude,
              lng: item.longitude,
            }}
            className={isActive(item.id) ? 'm-active' : ''}
            active={isActive(item.id)}
            icon={item.content}
            item={item}
            onToggle={item.onClick}
            items={items}
          />
        );
      });
    },
    [markers],
  );
  return (
    <div className="c-map">
      <APIProvider apiKey={process.env.REACT_APP_GOOGLE_API_KEY}>
        <GMap
          mapId={process.env.REACT_APP_GOOGLE_MAP_ID}
          defaultCenter={{ lat: 46.2048226, lng: 6.1018393 }}
          defaultZoom={12}
          disableDefaultUI
        >
          {markers?.length > 0 && renderMarkers(markers, 'stop')}
          {polylines && polylines.map((polyline, i) => <Polyline key={`route-${i}`} route={polyline} />)}
        </GMap>
        <MapHandler stops={markers} center={center} active={active} />
      </APIProvider>
    </div>
  );
});

export default GoogleMap;
