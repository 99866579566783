import React from 'react';
import { Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';

import './Main.scss';
import { ReactSVG } from 'react-svg';

const MainMenu = () => {
  const location = useLocation();
  const pathname = location.pathname;

  let selectedKey = pathname;

  if (selectedKey?.includes('route-plans')) {
    selectedKey = '/route-plans';
  } else if (selectedKey?.includes('routes')) {
    selectedKey = '/routes';
  } else if (selectedKey?.includes('orders') && !selectedKey?.includes('routes')) {
    selectedKey = '/orders';
  } else if (selectedKey?.includes('hubs')) {
    selectedKey = '/hubs';
  } else if (selectedKey?.includes('fleet')) {
    selectedKey = '/fleet';
  } else if (selectedKey?.includes('drivers') && !selectedKey?.includes('routes')) {
    selectedKey = '/drivers';
  } else if (selectedKey?.includes('scheduler')) {
    selectedKey = '/scheduler';
  } else if (selectedKey?.includes('users')) {
    selectedKey = '/users';
  } else if (selectedKey?.includes('settings')) {
    selectedKey = '/settings';
  }

  const items = [
    {
      label: (
        <div className="MenuItemContent">
          <ReactSVG className="icon" src="/icons/route-plans.svg" />
          <Link to={`/admin/route-plans`}>Route Plan</Link>
        </div>
      ),
      key: '/route-plans',
    },
    {
      label: (
        <div className="MenuItemContent">
          <ReactSVG className="icon" src="/icons/routes.svg" />
          <Link to="/admin/coaches">Routes</Link>
        </div>
      ),
      key: '/routes',
    },
    {
      label: (
        <div className="MenuItemContent">
          <ReactSVG className="icon" src="/icons/all_inbox.svg" />
          <Link to="/admin/orders">Orders</Link>
        </div>
      ),
      key: '/orders',
    },
    {
      label: (
        <div className="MenuItemContent">
          <ReactSVG className="icon" src="/icons/all_inbox.svg" />
          <Link to="/admin/hubs">Hubs</Link>
        </div>
      ),
      key: '/hubs',
    },
    {
      label: (
        <div className="MenuItemContent">
          <ReactSVG className="icon" src="/icons/directions_car.svg" />
          <Link to="/admin/fleet">Fleet</Link>
        </div>
      ),
      key: '/fleet',
    },
    {
      label: (
        <div className="MenuItemContent">
          <ReactSVG className="icon" src="/icons/directions_bike.svg" />
          <Link to="/admin/drivers">Drivers</Link>
        </div>
      ),
      key: '/drivers',
    },
    {
      label: (
        <div className="MenuItemContent">
          <ReactSVG className="icon" src="/icons/event_note.svg" />
          <Link to="/admin/scheduler">Scheduler</Link>
        </div>
      ),
      key: '/scheduler',
    },
    {
      label: (
        <div className="MenuItemContent">
          <ReactSVG className="icon" src="/icons/supervised_user_circle.svg" />
          <Link to="/admin/users">Users</Link>
        </div>
      ),
      key: '/users',
    },
    {
      label: (
        <div className="MenuItemContent">
          <ReactSVG className="icon" src="/icons/settings.svg" />
          <Link to="/admin/settings">Settings</Link>
        </div>
      ),
      key: '/settings',
    },
  ];

  return <Menu className={`MainMenu`} selectedKeys={[selectedKey]} mode="horizontal" items={items} />;
};

export default MainMenu;
