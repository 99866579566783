import { createAsyncThunk } from '@reduxjs/toolkit';
import { setGlobalLoading } from '../../../redux/slice';
import * as api from '../api';

export const doGetRoutesByDate = createAsyncThunk('Routes/doGetRoutesByDate', async (params, thunkAPI) => {
  try {
    thunkAPI.dispatch(setGlobalLoading(true));
    const { response, error } = await api.getRoutesByDate(params);
    thunkAPI.dispatch(setGlobalLoading(false));
    return error ? thunkAPI.rejectWithValue(error) : response;
  } catch (e) {
    thunkAPI.rejectWithValue(e);
    throw e;
  }
});

export const doDispatchRoutes = createAsyncThunk('Routes/doDispatchRoutes', async (params, thunkAPI) => {
  try {
    thunkAPI.dispatch(setGlobalLoading(true));
    const { response, error } = await api.dispatchRoutes(params);
    thunkAPI.dispatch(setGlobalLoading(false));
    return error ? thunkAPI.rejectWithValue(error) : response;
  } catch (e) {
    thunkAPI.rejectWithValue(e);
    throw e;
  }
});

export const doOptimizeRoutesByDate = createAsyncThunk('Routes/doOptimizeRoutesByDate', async (params, thunkAPI) => {
  try {
    thunkAPI.dispatch(setGlobalLoading(true));
    const { response, error } = await api.optimizeRoutesByDate(params);
    thunkAPI.dispatch(setGlobalLoading(false));
    return error ? thunkAPI.rejectWithValue(error) : response;
  } catch (e) {
    thunkAPI.rejectWithValue(e);
    throw e;
  }
});

export const doGetRoutes = createAsyncThunk('Routes/doGetRoutes', async (params, thunkAPI) => {
  try {
    thunkAPI.dispatch(setGlobalLoading(true));
    const { response, error } = await api.getRoutes(params);
    thunkAPI.dispatch(setGlobalLoading(false));
    return error ? thunkAPI.rejectWithValue(error) : response;
  } catch (e) {
    thunkAPI.rejectWithValue(e);
    throw e;
  }
});

export const doReorderOrdersForRoutes = createAsyncThunk(
  'Routes/doReorderOrdersForRoutes',
  async (params, thunkAPI) => {
    try {
      thunkAPI.dispatch(setGlobalLoading(true));
      const { response, error } = await api.reorderOrdersForRoutes(params);
      thunkAPI.dispatch(setGlobalLoading(false));
      return error ? thunkAPI.rejectWithValue(error) : response;
    } catch (e) {
      thunkAPI.rejectWithValue(e);
      throw e;
    }
  },
);
