import React from 'react';
import forEach from 'lodash/forEach';

import GoogleMap from '../../components/Map/GoogleMap';
import Icon from '../../icons';
import { COLORS, STEP_TYPE } from '../../constants';

const RouteMap = ({ mapData, vehicles = [], routes = [], onClickStep, active, hideDriverIds }) => {
  const newMarkers = [];
  const newPolylines = [];
  if (mapData) {
    forEach(routes, (route) => {
      const mapRoute = mapData[route.driver.id];
      if (hideDriverIds?.includes(route.driver.id)) return;
      if (!mapRoute) return;

      const steps = mapRoute.steps;
      const polyline = mapRoute.polyline;
      const color = mapRoute.color;
      forEach(steps, (step, indexStep) => {
        newMarkers.push({
          ...step,
          content: (
            <Icon
              type={step.type === STEP_TYPE.pickup ? 'MarkerPickup' : 'MarkerDropoff'}
              text={indexStep + 1}
              color={'#fff'}
              strokeColor={color}
              textColor={color}
            />
          ),
          latitude: Number(step.lat),
          longitude: Number(step.lng),
          offsetX: 1,
          offsetY: -6,
          duration: STEP_TYPE.pickup === step.type ? step.pickupDuration : step.deliveryDuration,
          onClick: () => onClickStep(step),
          color: color,
        });
      });
      if (polyline) {
        newPolylines.push({
          polyline: polyline,
          color: color,
          strokeWeight: 2,
          strokeOpacity: 0.8,
        });
      }
    });
    if (mapData['unscheduled']?.steps.length > 0) {
      forEach(mapData['unscheduled']?.steps, (stop, index) => {
        const stopOrder = index + 1;
        newMarkers.push({
          ...stop,
          content: (
            <Icon
              type={stop.type === STEP_TYPE.pickup ? 'MarkerPickup' : 'MarkerDropoff'}
              text={stopOrder}
              color={'#fff'}
              strokeColor={'#606060'}
            />
          ),
          latitude: Number(stop.lat),
          longitude: Number(stop.lng),
          offsetX: 1,
          offsetY: -6,
          onClick: () => onClickStep(stop),
        });
      });
    }
  }

  forEach(vehicles, (vehicle) => {
    const color = '#' + COLORS[vehicle.driver.id % (COLORS.length - 1)];
    if (hideDriverIds?.includes(vehicle.driver.id)) return;
    const steps = mapData[vehicle.driver.id]?.steps || [];

    const stepsPickup = steps.filter((step) => step.type === STEP_TYPE.pickup);

    let countPickup = 0;
    forEach(stepsPickup, (step) => {
      countPickup += step.orderIds?.length;
    });

    newMarkers.push({
      ...vehicle,
      id: `${vehicle.id}-start`,
      content: <Icon type="MarkerStart" color={'#fff'} strokeColor={color} />,
      latitude: Number(vehicle.startLat),
      longitude: Number(vehicle.startLng),
      offsetX: 1,
      offsetY: -6,
      countPickup,
      type: 'start',
      onClick: () => onClickStep({ id: `${vehicle.id}-start`, type: 'vehicle' }),
    });

    if (vehicle.endAddress && vehicle.endLat && vehicle.endLng) {
      newMarkers.push({
        ...vehicle,
        id: `${vehicle.id}-end`,
        content: <Icon type="MarkerEnd" color={'#fff'} strokeColor={color} />,
        latitude: Number(vehicle.endLat),
        longitude: Number(vehicle.endLng),
        offsetX: 1,
        offsetY: -6,
        type: 'end',
        onClick: () => onClickStep({ id: `${vehicle.id}-end`, type: 'vehicle' }),
      });
    }
  });
  return (
    <GoogleMap markers={newMarkers} className="mapbox-routePlan-detail" polylines={newPolylines} active={active} />
  );
};

export default RouteMap;
