import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { PAGE_SIZE, PAGE_SIZE_OPTIONS } from '../constants';
import { Button, Input, Modal, Spin } from 'antd';
import { ReactSVG } from 'react-svg';
import queryString from 'query-string';
import RidersTable from '../components/Tables/RidersTable';
import RiderForm from '../components/Forms/RiderForm';
import { selectAllDrivers } from '../modules/drivers/stores/slice';
import { doCreateDriver, doGetDrivers, doUpdateDriver } from '../modules/drivers/stores/thunk';

const initStateModal = {
  open: false,
  driver: null,
  action: 'ide',
};

const DriversPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const isFetching = useSelector((state) => state.driver.isFetching);
  const status = useSelector((state) => state.driver.status);
  const action = useSelector((state) => state.driver.action);
  const metadata = useSelector((state) => state.driver.metadata);
  const drivers = useSelector((state) => selectAllDrivers(state));
  const [searchValue, setSearchValue] = useState('');
  const [searchParams] = useSearchParams();

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [stateModal, setStateModal] = useState(initStateModal);

  const query = Object.fromEntries(searchParams);
  const currentPage = query.current || 1;
  const itemsPerPage = query.pageSize || PAGE_SIZE;
  const pagination = {
    current: currentPage || 1,
    pageSize: itemsPerPage,
    locale: { items_per_page: 'Drivers/Page' },
    total: metadata.totalItems || drivers.length,
    pageSizeOptions: PAGE_SIZE_OPTIONS,
    showSizeChanger: true,
    showTotal: (total) => `${total} Drivers`,
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  useEffect(() => {
    if (['create', 'update']?.includes(action) && status === 'succeeded') {
      dispatch(doGetDrivers({ page: currentPage, take: itemsPerPage, ...query }));
      handleCancelModal();
    }
  }, [isFetching]);

  useEffect(() => {
    setSearchValue(query.q || '');
  }, []);

  useEffect(() => {
    dispatch(doGetDrivers({ page: currentPage, take: itemsPerPage, ...query }));
  }, []);

  const handleCancelModal = () => {
    setStateModal(initStateModal);
  };

  const pushTo = (pushProps) => {
    navigate({
      pathname: pushProps.path || location.pathname,
      search: queryString.stringify(pushProps.query || {}, {
        skipNull: true,
      }),
      state: pushProps.state,
    });
  };

  const handleSubmitModal = (data) => {
    if (stateModal.action === 'new') {
      dispatch(doCreateDriver(data));
    } else {
      data.id = stateModal.driver.id;
      dispatch(doUpdateDriver(data));
    }
  };

  const handleFilter = (params, updatePath) => {
    delete params.pageSizeOptions;
    if (updatePath) {
      pushTo({ query: params });
    }
    const newCurrentPage = Number(params?.current || 1);
    const newItemsPerPage = Number(params?.pageSize) || PAGE_SIZE;
    dispatch(
      doGetDrivers({
        page: newCurrentPage,
        take: newItemsPerPage,
        ...params,
      }),
    );
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearch && handleSearch();
    }
  };

  const handleChangeSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const handleSearch = () => {
    handleFilter({ q: searchValue || '' }, true);
  };

  const handleTableChange = (params) => {
    const newParams = { ...query, ...params };
    handleFilter(newParams, true);
  };

  const onClickNew = () => {
    setStateModal({
      ...stateModal,
      open: true,
      driver: null,
      action: 'new',
    });
  };

  const onClickEdit = (record) => {
    setStateModal({
      ...stateModal,
      open: true,
      driver: {
        ...record,
        email: record.user?.email,
      },
      action: 'edit',
    });
  };

  const withAction = (record) => {
    return (
      <div className="actions">
        <Button type="link" className="edit" onClick={() => onClickEdit(record)}>
          <ReactSVG className="icon" src="/icons/edit.svg" />
        </Button>
        <Button type="link" className="more">
          <ReactSVG className="icon" src="/icons/more.svg" />
        </Button>
      </div>
    );
  };

  return (
    <Spin spinning={isFetching}>
      <div className="DriversPage Content">
        <div className="ContentHeader">
          <div className="HeaderTitle">Drivers</div>
          <div className="HeaderToolBar">
            <div className="ToolBarButtons">
              <Button className="CreateButton ButtonPrimary" type="primary" size="small" onClick={onClickNew}>
                Create New Driver
                <ReactSVG className="icon" src="/icons/plus.svg" />
              </Button>
            </div>
          </div>
        </div>
        <div className="page-header-table">
          <div className="search">
            <Input
              onChange={handleChangeSearch}
              onBlur={handleSearch}
              placeholder="Search"
              onKeyDown={handleKeyDown}
              suffix={<img src="/icons/search.svg" />}
              disabled={isFetching}
              value={searchValue}
            />
          </div>
          <div className="buttons">
            <Button className="FilterButton ButtonFilter">
              {selectedRowKeys?.length > 0 && <div className="count">{selectedRowKeys.length}</div>}
              Action <ReactSVG className="icon" src="/icons/arrow_drop_down.svg" />
            </Button>
          </div>
        </div>

        <div className="ContentMain">
          <RidersTable
            dataSource={drivers}
            pagination={pagination}
            withAction={withAction}
            rowKey="id"
            isFetching={isFetching}
            onSelectChange={onSelectChange}
            onChange={handleTableChange}
          />
        </div>

        <Modal open={stateModal.open} onCancel={handleCancelModal} footer={[]} width={792} forceRender>
          <RiderForm
            handleSubmit={handleSubmitModal}
            handleCancel={handleCancelModal}
            rider={stateModal.driver}
            action={stateModal.action}
            isFetching={isFetching}
          />
        </Modal>
      </div>
    </Spin>
  );
};

export default DriversPage;
