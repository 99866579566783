/* eslint-disable no-unused-vars */
import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { Button, Tooltip } from 'antd';
import { Droppable } from 'react-beautiful-dnd';
import { getListStyle, stringToColorHex } from '../../utils/common';
import DriverListSteps from './Steps';
import { COLORS } from '../../constants';
import IconHide from './IconHide';

const DriverList = ({
  hours,
  oneHourPx,
  isDragging,
  idsHide,
  routePlan,
  minutes,
  startTime,
  solutions,
  routes,
  stepCompleted,
  stepsHovered,
  onHoverStep,
  handleCloseStepPopup,
  onClickStep,
  setStepsHovered,
  onClickRoute,
  setShowMoreRoute,
  onClickHide,
  onClickLock,
  idsLock,
  onClickSwap,
  selectedMultipleSteps,
  multipleStepState,
  selectedStep,
}) => {
  return (
    <>
      {routes.map((route, index) => {
        const solution = isEmpty(solutions) ? {} : solutions[route.driver.id];
        const steps = solution || [];

        const workingPercent = route.workingPercent || 0;
        const percentLevel = 'high';
        const color = COLORS[route.driver.id % (COLORS.length - 1)];
        const isLock = idsLock?.includes(route.id);
        const isHide = idsHide?.includes(route.driver.id);

        return (
          <div
            key={index}
            className="tr"
            style={{
              minWidth: (hours.length + 1) * oneHourPx,
              width: '100%',
            }}
          >
            <div className={`td ${isDragging && 'isDragging'}`}>
              <div className="driver-name">
                <IconHide isHide={isHide} onClickHide={() => onClickHide(route.driver.id)} />
                <div className="driver-info">
                  <Tooltip title={route?.code}>
                    <div className="name">{route?.driver.name || ''}</div>
                  </Tooltip>
                  <div className="capacity">
                    <div className="text">
                      {route.shiftStart || 'N/A'} - {route.shiftEnd || 'N/A'}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Droppable key={`${route.driver.id}`} droppableId={`${route.driver.id}`} direction="horizontal">
              {(provided, snapshot) => (
                <div
                  className={`td ${isDragging && 'isDragging'}`}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  ref={provided.innerRef}
                  style={{
                    ...getListStyle(isDragging, snapshot.isDraggingOver),
                  }}
                >
                  <DriverListSteps
                    route={route}
                    steps={steps}
                    minutes={minutes}
                    startTime={startTime}
                    color={color}
                    solutions={solutions}
                    stepCompleted={stepCompleted}
                    oneHourPx={oneHourPx}
                    stepsHovered={stepsHovered}
                    onHoverStep={onHoverStep}
                    handleCloseStepPopup={handleCloseStepPopup}
                    onClickStep={onClickStep}
                    setStepsHovered={setStepsHovered}
                    onClickRoute={onClickRoute}
                    setShowMoreRoute={setShowMoreRoute}
                    routePlan={routePlan}
                    selectedMultipleSteps={selectedMultipleSteps}
                    multipleStepState={multipleStepState}
                    driverId={route.driver.id}
                    selectedStep={selectedStep}
                  />
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        );
      })}
    </>
  );
};

export default DriverList;
