import { createAsyncThunk } from '@reduxjs/toolkit';
import { setGlobalLoading } from '../../../redux/slice';
import * as api from '../api';

export const doGetHubs = createAsyncThunk('Hubs/doGetHubs', async (params, thunkAPI) => {
  try {
    thunkAPI.dispatch(setGlobalLoading(true));
    const { response, error } = await api.getHubs(params);
    thunkAPI.dispatch(setGlobalLoading(false));
    return error ? thunkAPI.rejectWithValue(error) : response;
  } catch (e) {
    thunkAPI.rejectWithValue(e);
    throw e;
  }
});

export const doGetHub = createAsyncThunk('Hubs/doGetHub', async (params, thunkAPI) => {
  try {
    thunkAPI.dispatch(setGlobalLoading(true));
    const { response, error } = await api.getHub(params.id);
    thunkAPI.dispatch(setGlobalLoading(false));
    return error ? thunkAPI.rejectWithValue(error) : response;
  } catch (e) {
    thunkAPI.rejectWithValue(e);
    throw e;
  }
});

export const doCreateHub = createAsyncThunk('Hubs/doCreateHub', async (params, thunkAPI) => {
  try {
    thunkAPI.dispatch(setGlobalLoading(true));
    const { response, error } = await api.createHub(params);
    thunkAPI.dispatch(setGlobalLoading(false));
    return error ? thunkAPI.rejectWithValue(error) : response;
  } catch (e) {
    thunkAPI.rejectWithValue(e);
    throw e;
  }
});

export const doUpdateHub = createAsyncThunk('Hubs/doUpdateHub', async (params, thunkAPI) => {
  try {
    thunkAPI.dispatch(setGlobalLoading(true));
    const { response, error } = await api.updateHub(params);
    thunkAPI.dispatch(setGlobalLoading(false));
    return error ? thunkAPI.rejectWithValue(error) : response;
  } catch (e) {
    thunkAPI.rejectWithValue(e);
    throw e;
  }
});

export const doRemoveHub = createAsyncThunk('Hubs/doRemoveHub', async (params, thunkAPI) => {
  try {
    thunkAPI.dispatch(setGlobalLoading(true));
    const { response, error } = await api.removeHub(params);
    thunkAPI.dispatch(setGlobalLoading(false));
    return error ? thunkAPI.rejectWithValue(error) : response;
  } catch (e) {
    thunkAPI.rejectWithValue(e);
    throw e;
  }
});
