import React from 'react';

const DriverTime = ({ hours, oneHourPx }) => {
  return (
    <div
      className="tr"
      style={{
        minWidth: hours.length * oneHourPx,
        width: '100%',
      }}
    >
      <div className="td">
        <div className="driver-name all-drivers">
          <b>Shifts</b>
        </div>
        {/* <div
          style={{
            width: "150px",
            display: "flex",
            alignItems: "center",
          }}
        /> */}
      </div>
      <div className="td">
        {hours.map((hour, index) => {
          return (
            <div className="time" key={index} style={{ width: `${oneHourPx}px` }}>
              {String(hour).padStart(2, '0')}:00
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DriverTime;
