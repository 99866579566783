import React, { useEffect, useMemo, useState } from 'react';
import './RoutesChart.scss';
import RoutePlanLeft from './RoutesChart/RoutePlanLeft';
import { COLORS, DATE_BACKEND_FORMAT, FULL_DATE_FORMAT, STEP_TYPE } from '../constants';
import { Button, DatePicker, message, Spin } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import range from 'lodash/range';
import findIndex from 'lodash/findIndex';
import cloneDeep from 'lodash/cloneDeep';
import { chain, sortBy, uniq } from 'lodash';
import dayjs from 'dayjs';
import { ReactSVG } from 'react-svg';
import { DragDropContext } from 'react-beautiful-dnd';
import DriverTime from './RoutesChart/DriverTime';
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie';

import {
  doDispatchRoutes,
  doGetRoutesByDate,
  doOptimizeRoutesByDate,
  doReorderOrdersForRoutes,
} from '../modules/routes/stores/thunk';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import socketIOClient from 'socket.io-client';
import { selectAllRoutes } from '../modules/routes/stores/slice';
import { doSynchronizeSchedulerForm } from '../modules/scheduler-forms/stores/thunk';
import {
  calculatePixelBetweenStops,
  getFirstStartAndLastEnd,
  getWidthChart,
  isDiffTwoArrayStops,
} from '../utils/common';
import DriverList from './RoutesChart/DriverList';
import RouteMap from './RoutesChart/RouteMap';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import UnScheduled from './RoutesChart/UnScheduled';
import { selectAllOrders } from '../modules/orders/stores/slice';
import { doChangeToDraft, doChangeToUnassigned, doGetOrders } from '../modules/orders/stores/thunk';
import RouteOrdersTable from '../components/Tables/RouteOrdersTable';
import SwapStopsToDriver from './RoutesChart/SwapStopsToDriver';
import { setShouldFitbounds } from '../redux/slice';

const initStateTimeline = {
  minTimePx: null,
  maxTimePx: null,
  maxTimeMinutes: null,
  minTimeMinutes: null,
};

const initMultipleSteps = {
  toDriverId: undefined,
  index: undefined,
  steps: [],
  visibleModal: false,
  searchedRoutes: [],
  selectedRoute: undefined,
  index: undefined,
};

const oneHourPx = 300;

const socketURI = process.env.REACT_APP_SOCKET_URI || '';
const socket = socketIOClient(socketURI);

const RoutesChart = () => {
  const { type } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const query = Object.fromEntries(searchParams);
  const [api, contextHolder] = message.useMessage();

  const dateCookie = Cookies.get('date');

  const routes = useSelector(selectAllRoutes);
  const orders = useSelector(selectAllOrders);

  const { isFetching: isFetchingShift } = useSelector((state) => state.schedulerForm);

  const { isFetching: isFetchingRoute, action: actionRoute, status: statusRoute } = useSelector((state) => state.route);

  const { isFetching: isFetchingOrder, action: actionOrder, status: statusOrder } = useSelector((state) => state.order);

  const [date, setDate] = useState();
  const [isDragging, setIsDragging] = useState(false);
  const [solutions, setSolutions] = useState({});
  const [originalSolutions, setOriginalSolutions] = useState({});
  const [mapData, setMapData] = useState({});
  const [startTime, setStartTime] = useState('08:00');
  const [hourStart, setHourStart] = useState(8);
  const [hourEnd, setHourEnd] = useState(8);
  const [stateTimeLine, setStateTimeLine] = useState(initStateTimeline);
  const [ordersSelected, setOrdersSelected] = useState([]);
  const [isOptimize, setIsOptimize] = useState(false);
  const [selectedStep, setSelectedStep] = useState({});
  const [multipleStepState, setMultipleStepState] = useState(initMultipleSteps);
  const [hideDriverIds, setHideDriverIds] = useState([]);

  const isFetching = isFetchingShift || isFetchingOrder || isOptimize || isFetchingRoute;

  const ordersInDate = orders.filter(
    (order) =>
      date &&
      dayjs(order.deliveryDate).format(DATE_BACKEND_FORMAT) === date?.format(DATE_BACKEND_FORMAT) &&
      order.status !== 'DRAFT',
  );

  const getAllDataForPage = () => {
    if (!date) return;
    dispatch(doGetRoutesByDate({ date: date?.format(DATE_BACKEND_FORMAT) }));
    dispatch(
      doGetOrders({
        deliveryDateFrom: date?.format(DATE_BACKEND_FORMAT),
        deliveryDateTo: date?.format(DATE_BACKEND_FORMAT),
        take: 1000000,
      }),
    );
    setIsOptimize(false);
  };

  useEffect(() => {
    if (type) {
      setOrdersSelected([]);
    }
  }, [type]);

  useMemo(() => {
    getAllDataForPage();
  }, [date]);

  useEffect(() => {
    if (query?.date) {
      setDate(dayjs(query?.date, DATE_BACKEND_FORMAT));
      Cookies.set('date', query?.date);
    } else if (dateCookie) {
      setDate(dateCookie ? dayjs(dateCookie, DATE_BACKEND_FORMAT) : dayjs());
    } else {
      setDate(dayjs());
      Cookies.set('date', dayjs().format(DATE_BACKEND_FORMAT));
    }
  }, [query?.date, dateCookie]);

  useEffect(() => {
    if (actionOrder === 'changeToUnassigned' && statusOrder === 'succeeded') {
      type === 'add-orders'
        ? navigate(`/admin/route-plans?date=${date?.format(DATE_BACKEND_FORMAT)}`)
        : dispatch(
            doGetOrders({
              deliveryDateFrom: date?.format(DATE_BACKEND_FORMAT),
              deliveryDateTo: date?.format(DATE_BACKEND_FORMAT),
              take: 1000000,
            }),
          );
      setOrdersSelected([]);
    }
    if (actionOrder === 'changeToDraft' && statusOrder === 'succeeded') {
      dispatch(
        doGetOrders({
          deliveryDateFrom: date?.format(DATE_BACKEND_FORMAT),
          deliveryDateTo: date?.format(DATE_BACKEND_FORMAT),
          take: 1000000,
        }),
      );
      setOrdersSelected([]);
    }
  }, [actionOrder, statusOrder]);

  useEffect(() => {
    if (actionRoute === 'optimizeByDate' && statusRoute === 'failed' && isOptimize) {
      setIsOptimize(false);
    }
  }, [actionRoute, statusRoute]);

  useEffect(() => {
    const dateFormat = date?.format(DATE_BACKEND_FORMAT);
    socket.connect();
    socket.on('routePlan', (data) => {
      if (data.event === 'optimizing.completed' && data.payload.name == dateFormat) {
        console.log('routePlan.optimizing.completed');
        if (type) {
          navigate('/route-plans?date=' + dateFormat);
        } else {
          getAllDataForPage();
        }

        setIsOptimize(false);
      }

      if (data.event === 'optimizing.error' && data.payload.name == dateFormat) {
        console.log('routePlan.optimizing.error');
        setIsOptimize(false);
        notification.error({
          message: 'Optimize failed',
          style: { top: '52px' },
        });
      }
    });
    return () => {
      socket.disconnect();
    };
  }, [date]);

  console.log({ date });

  const handleSynchronize = async () => {
    await dispatch(doSynchronizeSchedulerForm({ date: date?.format(DATE_BACKEND_FORMAT) }));
    getAllDataForPage();
  };

  const handleOptimize = async () => {
    setIsOptimize(true);
    await dispatch(doOptimizeRoutesByDate({ date: date?.format(DATE_BACKEND_FORMAT) }));
  };

  const handleDispatch = () => {
    const routesToDispatchIds = routes.filter((route) => route.steps.length > 0)?.map((route) => route.id);
    dispatch(doDispatchRoutes({ ids: routesToDispatchIds }));
  };

  const getSummaryRoutes = () => {
    let stepsDone = 0;
    let stepsMissed = 0;
    let stepsTotal = 0;
    let workingMins = 0;
    let distance = 0;
    const totalOrders = ordersInDate.length;
    routes?.forEach((route) => {
      if (hideDriverIds?.includes(route.driver.id)) return;
      route.steps
        ?.filter((step) => step.type !== STEP_TYPE.driver)
        .forEach((step) => {
          const order = orders.find((o) => o.id === step.orderId);
          if (order?.status === 'COMPLETED') stepsDone += 1;
          if (order?.status === 'SKIPPED') stepsMissed += 1;
          stepsTotal += 1;
          workingMins += step.workingMins;
          distance += step.distance;
        });
    });

    return { stepsDone, stepsTotal, workingMins, distance, stepsMissed, totalOrders };
  };

  const summaryRoutes = getSummaryRoutes();

  const handleReorder = async () => {
    const drivers = [];
    const unscheduled = [];
    const allOrderIds = [];
    routes.map((route) => {
      const isDiff = isDiffTwoArrayStops(solutions[route.driver.id], originalSolutions[route.driver.id]);
      if (isDiff) {
        const driver = { driverId: route.id, steps: [] };
        solutions[route.driver.id].map((step) => {
          if (step.type.toUpperCase() === 'PICKUP') {
            step.orderIds.forEach((id) => {
              const order = orders.find((order) => order.id === id);

              if (
                order &&
                order.status != 'SKIPPED' &&
                ((step.type.toUpperCase() === 'PICKUP' && order.pickupStatus != 'COLLECTED') ||
                  (step.type.toUpperCase() === 'DELIVERY' && order.status != 'COMPLETED'))
              ) {
                driver.steps.push(`${order.id}_${step.type.toUpperCase()}`);
                allOrderIds.push(order.id);
              }
            });
          } else {
            const order = orders.find((order) => order.id === step.orderId);

            if (
              order &&
              order.status != 'SKIPPED' &&
              ((step.type.toUpperCase() === 'PICKUP' && order.pickupStatus != 'COLLECTED') ||
                (step.type.toUpperCase() === 'DELIVERY' && order.status != 'COMPLETED'))
            ) {
              driver.steps.push(`${order.id}_${step.type.toUpperCase()}`);
              allOrderIds.push(order.id);
            }
          }
        });
        drivers.push(driver);
      }
    });
    if (drivers.length === 0) return;

    const isDiffUnscheduled = isDiffTwoArrayStops(solutions['unscheduled'], originalSolutions['unscheduled']);
    if (isDiffUnscheduled) {
      solutions['unscheduled'].map((step) => {
        const order = orders.find((order) => order.id === step.orderId);
        if (
          order &&
          order.status != 'SKIPPED' &&
          ((step.type.toUpperCase() === 'PICKUP' && order.pickupStatus != 'COLLECTED') ||
            (step.type.toUpperCase() === 'DELIVERY' && order.status != 'COMPLETED'))
        ) {
          unscheduled.push(order.id);
          allOrderIds.push(order.id);
        }
      });
    }
    setIsOptimize(true);

    await dispatch(
      doReorderOrdersForRoutes({
        date: date?.format(DATE_BACKEND_FORMAT),
        routes: drivers,
        unscheduled: uniq(unscheduled),
        allOrderIds: uniq(allOrderIds),
      }),
    );
  };

  const moveSteps = () => {
    const updatedSolutions = cloneDeep(solutions);
    let moveIndex = multipleStepState.index;
    let targetDriverId = multipleStepState.toDriverId;
    const sortedAndGroupedData = chain(multipleStepState.steps || [])
      .sortBy('orderNumber')
      .groupBy('driverId')
      .value();

    if (typeof moveIndex !== 'number') {
      targetDriverId = targetDriverId === 'unscheduled' ? targetDriverId : targetDriverId;
      moveIndex = 0;
    }
    const moveAfterStep = { ...updatedSolutions[targetDriverId][moveIndex] };
    Object.keys(sortedAndGroupedData).forEach((fromDriverId) => {
      let sourceClone = [...updatedSolutions[fromDriverId]];

      const stepsNeedMove = sortBy(sortedAndGroupedData[fromDriverId], 'type');
      let stepsToMove = [];

      stepsNeedMove.forEach((step) => {
        if (step.type === STEP_TYPE.pickup) {
          const stepPushPickup = sourceClone.find(
            (stepSc) => step.id === stepSc.id && stepSc.type === STEP_TYPE.pickup,
          );
          const stepsPush = [stepPushPickup];
          const orderIds = step.orderIds;

          orderIds.forEach((id) => {
            stepsPush.push(sourceClone.find((step) => step.orderId === id && step.type === STEP_TYPE.delivery));
          });
          stepsToMove = [...stepsToMove, ...stepsPush];
        } else {
          const stepPushDelivery = sourceClone.find(
            (stepSc) => step.id === stepSc.id && stepSc.type === STEP_TYPE.delivery,
          );

          stepsToMove.push(stepPushDelivery);

          const stepPickup = sourceClone.find(
            (stepSc) => stepSc.orderIds?.includes(stepPushDelivery.orderId) && stepSc.type === STEP_TYPE.pickup,
          );
          stepsToMove.push({
            ...stepPickup,
            id: stepPickup.orderIds.length > 1 ? uuidv4() : stepPickup.id,
            orderIds: [stepPushDelivery.orderId],
          });

          if (stepPushDelivery.orderIds.length > 1) {
            sourceClone = sourceClone.map((sc) => {
              if (stepPickup.id === sc.id) {
                return {
                  ...sc,
                  orderIds: sc.orderIds.filter((id) => id !== stepPushDelivery.orderId),
                };
              }
              return sc;
            });
          }
        }
      });

      sourceClone = sourceClone.filter((step) => {
        return !stepsToMove.some((moveStep) => step.id === moveStep.id && step.type === moveStep.type);
      });

      const targetClone = fromDriverId === targetDriverId ? [...sourceClone] : [...updatedSolutions[targetDriverId]];

      moveIndex = targetClone.findIndex((step) => step.id === moveAfterStep.id && step.type === moveAfterStep.type);

      const pushData = sortBy(stepsToMove, 'type');

      pushData.reverse().forEach((step) => {
        moveIndex++;
        targetClone.splice(moveIndex, 0, {
          ...step,
          marginLeft: 8,
        });
      });

      updatedSolutions[targetDriverId] = targetClone;

      if (fromDriverId !== targetDriverId) {
        updatedSolutions[fromDriverId] = sourceClone;
      }
    });

    setSolutions(updatedSolutions);
    setMultipleStepState(initMultipleSteps);
  };

  const selectedMultipleSteps = (step, driverId) => {
    console.log({ step });
    const cloneSteps = [...multipleStepState.steps];
    const indexStep = cloneSteps.findIndex(
      (s) => s.stepId === step.stepId && s.type === step.type && s.driverId === driverId,
    );
    if (indexStep > -1) {
      cloneSteps.splice(indexStep, 1);
    } else {
      cloneSteps.unshift({ ...step, driverId });
    }
    setMultipleStepState({ ...multipleStepState, steps: cloneSteps });
  };

  const onClickStep = (step) => {
    if (selectedStep.id === step.id && selectedStep.type === step.type) {
      setSelectedStep({});
    } else {
      setSelectedStep({ id: step.id, type: step.type });
    }
  };

  const hours = range(hourStart, hourEnd + 2);
  const minutes = range(0, (hourEnd - hourStart) * 2 + 2);

  useEffect(() => {
    if (routes?.length) {
      const [startAt, endAt] = getFirstStartAndLastEnd(routes);
      const startHour = startAt && startAt?.split(':')[0];
      setHourStart(Number(startHour));
      const endHour = endAt && endAt?.split(':')[0];
      setHourEnd(Number(endHour));
      const startHourFull = `${startHour}:00`;
      setStartTime(startHourFull);
      if (stateTimeLine.maxTimePx || stateTimeLine.minTimePx) {
        if (stateTimeLine.minTimeMinutes) {
          const pxScroll = convertMinutesToPixel(stateTimeLine.minTimeMinutes, oneHourPx);
          containerTimelineRef.current.scrollTo({
            left: pxScroll - 80,
          });
        }
        setStateTimeLine(initStateTimeline);
      }
    }
  }, [routes, oneHourPx, date]);
  const onDragStart = () => {
    setIsDragging(true);
  };

  const onDragEnd = (result) => {
    setIsDragging(false);
    const { source, destination } = result;
    if (!destination) return;
    if (source.droppableId === destination.droppableId) {
      if (destination.index === source.index) return;
      const steps = solutions[source.droppableId];
      const sourceClone = [...steps];
      const stop = sourceClone[source.index];
      const type = stop.type === STEP_TYPE.pickup ? STEP_TYPE.delivery : STEP_TYPE.pickup;
      if (stop.type === STEP_TYPE.delivery) {
        const stopPickupIndex = findIndex(sourceClone, (s) => s.orderIds?.includes(stop.orderId) && s.type === type);
        if (destination.index <= stopPickupIndex) {
          api.open({
            type: 'error',
            content:
              'Preventing the relocation of the drop-off point ahead of the pickup point is a required restriction.',
            style: { marginTop: '52px' },
          });
          return;
        }
      } else {
        let isFalse = false;
        const orderIds = stop.orderIds;
        forEach(orderIds, (orderId) => {
          if (isFalse) return;
          const stopOrderIndex = findIndex(sourceClone, (s) => s.orderId === orderId && s.type === type);
          isFalse = destination.index >= stopOrderIndex;
        });
        if (isFalse) {
          api.open({
            type: 'error',
            content:
              'Preventing the relocation of the drop-off point ahead of the pickup point is a required restriction.',
            style: { marginTop: '52px' },
          });
          return;
        }
      }
      const stopLeftIndex =
        type === STEP_TYPE.delivery
          ? findIndex(sourceClone, (s) => s.orderId === stop.orderId && s.type === type)
          : findIndex(
              sourceClone,
              (s) => JSON.stringify(s.orderIds) === JSON.stringify(stop.orderIds) && s.type === type,
            );
      if (
        (type === STEP_TYPE.delivery && destination.index >= stopLeftIndex) ||
        (type === STEP_TYPE.pickup && destination.index <= stopLeftIndex)
      )
        return;
      const [removed] = sourceClone.splice(source.index, 1);
      sourceClone.splice(destination.index, 0, {
        ...removed,
        duration: 20,
        marginLeft: 8,
      });
      const newSolutions = { ...solutions, [source.droppableId]: sourceClone };
      setSolutions(newSolutions);
      if (destination.droppableId?.includes('assign') || destination.droppableId === 'unscheduled') return;
    } else {
      moveStep(source, destination);
    }
  };

  const moveStep = (droppableSource, droppableDestination) => {
    const stepsSource = solutions[droppableSource.droppableId];
    const stepsDestination = solutions[droppableDestination.droppableId];
    const sourceClone = [...stepsSource];
    const destClone = [...stepsDestination];
    const stop = sourceClone[droppableSource.index];
    const type = stop.type === STEP_TYPE.pickup ? STEP_TYPE.delivery : STEP_TYPE.pickup;
    const newSolutions = { ...solutions };

    if (stop.type === STEP_TYPE.pickup) {
      const orderIds = stop.orderIds;
      const [removed] = sourceClone.splice(droppableSource.index, 1);
      const destPickupIndex = destClone.findIndex(
        (dest) => dest.lat === removed.lat && dest.lng === removed.lng && dest.locationId === removed.locationId,
      );
      const destPickup = destPickupIndex === -1 ? null : destClone[destPickupIndex];
      if (destPickup) {
        destClone.splice(destPickupIndex, 1, {
          ...destPickup,
          // duration: 10,
          orderIds: [...destPickup.orderIds, ...orderIds],
        });
      } else {
        destClone.splice(droppableDestination.index, 0, {
          ...removed,
          id: uuidv4(),
          orderIds: [...orderIds],
          marginLeft: 8,
          // width: '20px',
        });
      }
      if (orderIds.length > 0) {
        orderIds.forEach((orderId) => {
          const stopLeftIndex = findIndex(sourceClone, (s) => s.orderId === orderId && s.type === type);
          if (stopLeftIndex > -1) {
            const destinationIndex = destPickup ? droppableDestination.index : droppableDestination.index + 1;
            const [removedLeft] = sourceClone.splice(stopLeftIndex, 1);
            destClone.splice(destinationIndex, 0, {
              ...removedLeft,
              id: uuidv4(),
              marginLeft: 8,
            });
          }
        });
      }
    } else {
      const [removed] = sourceClone.splice(droppableSource.index, 1);
      destClone.splice(droppableDestination.index, 0, {
        ...removed,
        id: uuidv4(),
        marginLeft: 8,
      });
      const stopPickupIndex = findIndex(sourceClone, (s) => s.orderIds?.includes(stop.orderId) && s.type === type);
      const stopPickup = sourceClone[stopPickupIndex];
      const orderIds = stopPickup.orderIds;
      if (orderIds?.length === 1) {
        const destinationIndex = droppableDestination.index;
        const [removedLeft] = sourceClone.splice(stopPickupIndex, 1);

        destClone.splice(destinationIndex, 0, {
          ...removedLeft,
          id: uuidv4(),
          marginLeft: 8,
          orderIds: [stop.orderId],
        });
      } else if (orderIds?.length > 1) {
        const destinationIndex =
          type === STEP_TYPE.pickup ? droppableDestination.index : droppableDestination.index + 1;
        const orderIdIndex = findIndex(orderIds, (id) => id === stop.orderId);
        orderIds.splice(orderIdIndex, 1);
        sourceClone.splice(stopPickupIndex, 1, {
          ...stopPickup,
          orderIds,
          id: uuidv4(),
          marginLeft: 8,
        });
        destClone.splice(destinationIndex, 0, {
          ...stopPickup,
          orderIds: [stop.orderId],
          marginLeft: 8,
          id: uuidv4(),
        });
      }
    }
    newSolutions[droppableSource.droppableId] = sourceClone;
    newSolutions[droppableDestination.droppableId] = destClone;
    setSolutions(newSolutions);
  };

  const handleCancelMultiStops = () => {
    setMultipleStepState(initMultipleSteps);
  };

  const onSelectChangeAddOrders = (newSelectedRowKeys) => {
    setOrdersSelected(newSelectedRowKeys);
  };

  const changeOrdersToUnassigned = async () => {
    await dispatch(
      doChangeToUnassigned({
        orderCodes: ordersSelected,
      }),
    );
  };

  const changeOrdersToDraft = async () => {
    await dispatch(
      doChangeToDraft({
        orderCodes: ordersSelected,
      }),
    );
  };

  const handleKeyUp = (event) => {
    if (event.key === 'Meta' || event.key === 'Control' || event.key === 'Shift') {
      if (multipleStepState.steps?.length > 0) {
        setMultipleStepState({
          ...multipleStepState,
          visibleModal: true,
        });
      }
    }
  };

  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp);
    return () => {
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [multipleStepState, stateTimeLine]);

  const formatSolutions = () => {
    const solutionsTmp = {};
    const mapDataTmp = {};
    const unassignOrders = orders.filter((o) => o.status === 'UNASSIGNED');

    const stepsUnassigned = [];
    let indexUnassigned = 1;
    for (let index = 0; index < unassignOrders.length; index++) {
      const order = unassignOrders[index];

      if (order) {
        stepsUnassigned.push({
          id: `${order.orderCode}-pickup`,
          stepId: null,
          name: order.pickupName,
          location: order.pickupName,
          address: order.pickupAddress,
          lat: order.pickupAddressLat,
          lng: order.pickupAddressLng,
          startTime: order?.pickupTimeStart,
          type: STEP_TYPE.pickup,
          endTime: order?.pickupTimeEnd,
          status: order.status,
          index: indexUnassigned,
          orderNumber: indexUnassigned,
          duration: order.pickupDuration,
          orderId: order.id,
          orderIds: [order.id],
          orderCode: order.orderCode,
          deliveryName: order.deliveryName,
          orderTypes: order.types,
          color: '#606060',
          routeId: 'unscheduled',
          unscheduledReason: order.unscheduledReason,
        });
        stepsUnassigned.push({
          id: `${order.orderCode}-delivery`,
          stepId: null,
          name: order.deliveryName,
          location: order.deliveryName,
          address: order.deliveryAddress,
          lat: order.deliveryAddressLat,
          lng: order.deliveryAddressLng,
          startTime: order?.deliveryTimeStart,
          type: STEP_TYPE.delivery,
          endTime: order?.deliveryTimeEnd,
          status: order.status,
          index: indexUnassigned + 1,
          orderNumber: indexUnassigned + 1,
          duration: order.deliveryDuration,
          orderId: order.id,
          orderCode: order.orderCode,
          deliveryName: order.deliveryName,
          orderTypes: order.types,
          color: '#606060',
          routeId: 'unscheduled',
          unscheduledReason: order.unscheduledReason,
        });
        indexUnassigned += 2;
      }
    }
    const rsUnassignedStops = {
      steps: stepsUnassigned,
      color: '#606060',
    };

    solutionsTmp['unscheduled'] = stepsUnassigned;

    mapDataTmp['unscheduled'] = rsUnassignedStops;

    routes.forEach((route) => {
      const steps = calculatePixelBetweenStops(route?.steps || [], route, oneHourPx);
      const driver = route.driver;
      const newSteps = [];
      const color = '#' + COLORS[driver.id % (COLORS.length - 1)];
      let indexStep = 1;

      if (steps.length > 0) {
        for (let index = 0; index < steps.length; index++) {
          const step = steps[index];
          const width = step.arrivalTime ? getWidthChart(step.arrivalTime, step.finishTime, oneHourPx) : 1;
          if (!step) return;
          const stepResponse = {
            id: uuidv4(),
            stepId: step.id,
            orderNumber: step.orderNumber,
            name: step.type === STEP_TYPE.pickup ? step.order.pickupName : step.order.deliveryName,
            location: step.order.pickupName,
            address: step.order.pickupAddress,
            lat: step.order.pickupAddressLat,
            lng: step.order.pickupAddressLng,
            arrivalTime: step.arrivalTime,
            finishTime: step.finishTime,
            startTime: step.order?.deliveryTimeStart,
            endTime: step.order?.deliveryTimeEnd,
            status: step.status,
            type: step.type,
            distance: step.distance,
            orderNumber: step.orderNumber,
            index: indexStep,
            duration: step.type === STEP_TYPE.pickup ? step.order.pickupDuration : step.order.deliveryDuration,
            marginLeft: step.marginLeft,
            width,
            orderId: step.orderId,
            orderIds: [step.orderId],
            orderCode: step.order?.orderCode,
            deliveryName: step.order?.deliveryName,
            orderRef: step.order?.orderRef,
            orderStatus: step.order?.status,
            driverName: driver?.name,
            travelMins: step.travelMins || 0,
            workingMins: step.workingMins || 0,
            waitingMins: step.waitingMins || 0,
            color,
            orderTypes: step.order?.types,
          };

          if (step.type === STEP_TYPE.delivery) {
            stepResponse.location = step.order.deliveryName;
            stepResponse.address = step.order.deliveryAddress;
            stepResponse.lat = step.order.deliveryAddressLat;
            stepResponse.lng = step.order.deliveryAddressLng;
          } else if (step.type === STEP_TYPE.pickup && index < steps.length - 1) {
            delete stepResponse.orderId; // No need
            delete stepResponse.status; // No need
            delete stepResponse.orderStatus; // No need
            let samePickup = false;
            const orderIds = [...stepResponse.orderIds];
            do {
              const currentNext = steps[index];
              const stepNext = steps[index + 1];
              const widthNextStep = stepNext.arrivalTime
                ? getWidthChart(stepNext.arrivalTime, stepNext.finishTime, oneHourPx)
                : 1;
              samePickup =
                stepNext.type === STEP_TYPE.pickup &&
                currentNext.status === stepNext.status &&
                currentNext.order.pickupAddressLat === stepNext.order.pickupAddressLat &&
                currentNext.order.pickupAddressLng === stepNext.order.pickupAddressLng &&
                currentNext.order.pickupPhone === stepNext.order.pickupPhone;

              if (samePickup) {
                stepResponse.duration += stepNext.duration;
                stepResponse.distance += stepNext.distance;
                stepResponse.travelMins += stepNext.travelMins;
                stepResponse.workingMins += stepNext.workingMins;
                stepResponse.waitingMins = stepNext.waitingMins;
                stepResponse.finishTime = stepNext.finishTime;
                stepResponse.width += widthNextStep;
                stepResponse.marginLeft += stepNext.marginLeft;
                orderIds.push(stepNext.orderId);
                index++;
              }
            } while (samePickup);
            stepResponse.orderIds = orderIds;
          }
          newSteps.push(stepResponse);
          indexStep += 1;
        }
      }

      solutionsTmp[route.driver.id] = newSteps;
      solutionsTmp[`assign_${route.driver.id}`] = [];
      mapDataTmp[route.driver.id] = {
        polyline: route.polyline,
        steps: newSteps,
        color: color,
        endAddressLat: route.endAddressLat,
        endAddressLng: route.endAddressLng,
        startAddressLat: route.startAddressLat,
        startAddressLng: route.startAddressLng,
      };
    });

    if (selectedStep?.id) {
      setSelectedStep({});
    }
    if (hideDriverIds?.length > 0) {
      setHideDriverIds();
    }
    setSolutions(solutionsTmp);
    setOriginalSolutions(solutionsTmp);
    setMapData(mapDataTmp);
    dispatch(setShouldFitbounds(true));
  };

  const onHideDriver = (driverId) => {
    if (hideDriverIds?.includes(driverId)) {
      setHideDriverIds(hideDriverIds.filter((id) => id !== driverId));
    } else {
      setHideDriverIds([...hideDriverIds, driverId]);
    }
  };

  useEffect(() => {
    formatSolutions();
  }, [routes, oneHourPx, orders]);

  const onChangeDate = (dateInput) => {
    setDate(dateInput);
    setSearchParams({ date: dateInput.format(DATE_BACKEND_FORMAT) });
    Cookies.set('date', dateInput.format(DATE_BACKEND_FORMAT));
  };

  return (
    <Spin tip="Loading..." spinning={isFetching}>
      {contextHolder}
      <div className="RoutesChart">
        <RoutePlanLeft deliveryDate={date} orders={ordersInDate} />
        {!type && (
          <div className="wrapper">
            <div className="select-date">
              <DatePicker
                onChange={onChangeDate}
                value={date}
                format={FULL_DATE_FORMAT}
                allowClear={false}
                size="large"
              />
            </div>
            <div className="wrapper-map">
              <RouteMap
                vehicles={routes}
                mapData={mapData}
                routes={routes}
                onClickStep={onClickStep}
                active={selectedStep}
                hideDriverIds={hideDriverIds}
              />
            </div>
            <div className="wrapper-chart">
              <div className="ChartContent">
                <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
                  <div className="tbody unscheduled" style={{ width: '100%', height: '48px' }}>
                    <UnScheduled
                      steps={solutions['unscheduled']}
                      realSteps={solutions['unscheduled'] || []}
                      stepsHovered={{}}
                      setStepsHovered={() => {}}
                      onHoverStep={() => {}}
                      handleCloseStepPopup={() => {}}
                      isDragging={isDragging}
                      idsHides={[]}
                      onClickStep={onClickStep}
                      onClickHide={() => {}}
                      multipleStepState={multipleStepState}
                      selectedMultipleSteps={selectedMultipleSteps}
                    />
                  </div>
                  <div className="scrollCustom tbody">
                    <DriverTime hours={hours} oneHourPx={oneHourPx} />
                    <DriverList
                      isDragging={isDragging}
                      hours={hours}
                      oneHourPx={oneHourPx}
                      minutes={minutes}
                      startTime={startTime}
                      solutions={solutions}
                      routes={routes}
                      idsLock={[]}
                      onClickStep={onClickStep}
                      onClickRoute={() => {}}
                      multipleStepState={multipleStepState}
                      selectedMultipleSteps={selectedMultipleSteps}
                      selectedStep={selectedStep}
                      onClickHide={onHideDriver}
                      idsHide={hideDriverIds}
                    />
                  </div>
                </DragDropContext>
              </div>
            </div>
            <div className="summary-routes">
              <p>
                <b>
                  {summaryRoutes.stepsDone} / {summaryRoutes.stepsTotal}
                </b>{' '}
                Completed
              </p>
              <p>
                <b>
                  {summaryRoutes.stepsMissed} / {summaryRoutes.stepsTotal}
                </b>{' '}
                Missed
              </p>
              <p>
                <b>
                  {summaryRoutes.stepsTotal} / {summaryRoutes.totalOrders * 2}
                </b>{' '}
                Scheduled
              </p>
              <p>
                <b>{summaryRoutes.workingMins.toFixed(2)}</b> Working mins
              </p>
              <p>
                <b>{(summaryRoutes.distance / 1000).toFixed(2)}</b> total Km
              </p>
            </div>
            <div className="actions-route-plan">
              <div className="left">
                <Button type="primary" onClick={handleSynchronize}>
                  Synchronize
                  <ReactSVG className="icon" src="/icons/sync.svg" />
                </Button>
              </div>
              <div className="right">
                <Button type="primary" ghost onClick={handleReorder}>
                  Apply
                  <ReactSVG className="icon" src="/icons/check.svg" />
                </Button>
                <Button type="primary" onClick={handleOptimize}>
                  Optimize Route
                  <ReactSVG className="icon" src="/icons/sync.svg" />
                </Button>
                <Button size="large" className="btn-dispatch-route" onClick={handleDispatch}>
                  Dispatch to Drivers
                  <ReactSVG className="icon" src="/icons/dispatch-to-driver.svg" />
                </Button>
              </div>
            </div>
          </div>
        )}
        {(type === 'add-orders' || type === 'orders') && (
          <div className="wrapper-orders">
            <h2 className="title">{type === 'add-orders' ? 'Add Orders' : 'Orders'}</h2>
            <div className="content">
              <div className="left">
                <RouteOrdersTable
                  dataSource={orders.filter((o) => (type === 'orders' ? o.status !== 'DRAFT' : o.status === 'DRAFT'))}
                  selectedRowKeys={ordersSelected}
                  onSelectChange={onSelectChangeAddOrders}
                  rowKey="orderCode"
                />
              </div>
              <div className="right"></div>
            </div>
            <div className="actions-orders">
              <div className="left"></div>
              <div className="right">
                {type === 'orders' && (
                  <>
                    <Link to={`/admin/route-plans/add-orders?date=${date?.format(DATE_BACKEND_FORMAT)}`}>
                      <Button type="primary">
                        Add Orders
                        <ReactSVG className="icon" src="/icons/plus.svg" />
                      </Button>
                    </Link>
                    <Button danger disabled={ordersSelected.length === 0} onClick={changeOrdersToDraft}>
                      Remove <CloseOutlined className="icon" />
                    </Button>
                    <Button danger disabled={ordersSelected.length === 0} onClick={changeOrdersToUnassigned}>
                      Unassign <CloseOutlined className="icon" />
                    </Button>
                  </>
                )}
                {type === 'add-orders' && (
                  <Button type="primary" onClick={changeOrdersToUnassigned}>
                    Add Orders To Date
                    <ReactSVG className="icon" src="/icons/plus.svg" />
                  </Button>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <SwapStopsToDriver
        state={multipleStepState}
        setMultipleStepState={setMultipleStepState}
        handleOk={moveSteps}
        handleCancel={handleCancelMultiStops}
        routes={routes}
        solutions={solutions}
        orders={ordersInDate || []}
      />
    </Spin>
  );
};
export default RoutesChart;
